//Alerts
export const ALERT_SETTED = "ALERT_SETTED";
export const ALERT_REPLACED = "ALERT_REPLACED";
export const ALERT_REMOVED = "ALERT_REMOVED";

//Global
export const LOADINGSPINNER_UPDATED = "LOADINGSPINNER_UPDATED";
export const CURRENTNAV_CHANGED = "CURRENTNAV_CHANGED";
export const MENU_TOGGLED = "MENU_TOGGLED";
export const POPUP_TOGGLED = "POPUP_TOGGLED";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const GLOBAL_CLEARED = "GLOBAL_CLEARED";
export const BACKUP_GENERATED = "BACKUP_GENERATED";
export const BD_RESTORED = "BD_RESTORED";
export const FOOTER_HEIGHT_SETTED = "FOOTER_HEIGHT_SETTED";
export const NAVBAR_HEIGHT_SETTED = "NAVBAR_HEIGHT_SETTED";
export const PENALTY_LOADED = "PENALTY_LOADED";
export const DISCOUNT_LOADED = "DISCOUNT_LOADED";
export const SALARIES_LOADED = "SALARIES_LOADED";
export const VALUES_UPDATED = "VALUES_UPDATED";
export const SALARIES_UPDATED = "SALARIES_UPDATED";

//User
export const USER_LOADED = "USER_LOADED";
export const USERS_LOADED = "USERS_LOADED";
export const USERSBK_LOADED = "USERSBK_LOADED";
export const OTHERVALUES_LOADED = "OTHERVALUES_LOADED";
export const USER_REGISTERED = "USER_REGISTERED";
export const USER_UPDATED = "USER_UPDATED";
export const USER_DELETED = "USER_DELETED";
export const USERFORLIST_ADDED = "USERFORLIST_ADDED";
export const USERFROMLIST_REMOVED = "USERFROMLIST_REMOVED";
export const USERSEARCHTYPE_CHANGED = "USERSEARCHTYPE_CHANGED";
export const USER_CLEARED = "USER_CLEARED";
export const USERS_CLEARED = "USERS_CLEARED";
export const SEARCH_CLEARED = "SEARCH_CLEARED";
export const USERSBK_CLEARED = "USERSBK_CLEARED";
export const USER_ERROR = "USER_ERROR";
export const USERS_ERROR = "USERS_ERROR";
export const USERSBK_ERROR = "USERSBK_ERROR";

//Auth
export const USERAUTH_LOADED = "USERAUTH_LOADED";
export const USERAUTH_UPDATED = "USERAUTH_UPDATED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const STARTLOGOUT = "STARTLOGOUT";
export const FINISHLOGOUT = "FINISHLOGOUT";
export const AUTH_ERROR = "AUTH_ERROR";

//Classes
export const CLASS_LOADED = "CLASS_LOADED";
export const CLASSES_LOADED = "CLASSES_LOADED";
export const ACTIVECLASSES_LOADED = "ACTIVECLASSES_LOADED";
export const TEACHERHOURS_LOADED = "TEACHERHOURS_LOADED";
export const CLASS_REGISTERED = "CLASS_REGISTERED";
export const CLASS_UPDATED = "CLASS_UPDATED";
export const CLASSCATEGORY_UPDATED = "CLASSCATEGORY_UPDATED";
export const CLASSSTUDENT_ADDED = "CLASSSTUDENT_ADDED";
export const CLASSSTUDENT_REMOVED = "CLASSSTUDENT_REMOVED";
export const CLASS_DELETED = "CLASS_DELETED";
export const CLASS_CLEARED = "CLASS_CLEARED";
export const CLASSES_CLEARED = "CLASSES_CLEARED";
export const CLASS_ERROR = "CLASS_ERROR";
export const CLASSES_ERROR = "CLASSES_ERROR";
export const CLASSES_PDF_ERROR = "CLASSES_PDF_ERROR";

//Register
export const REGISTER_LOADED = "REGISTER_LOADED";
export const REGISTERS_LOADED = "REGISTERS_LOADED";
export const REGISTER_CLOSED = "REGISTER_CLOSED";
export const REGISTER_DELETED = "REGISTER_DELETED";
export const REGISTER_CLEARED = "REGISTER_CLEARED";
export const REGISTERS_CLEARED = "REGISTERS_CLEARED";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const REGISTERS_ERROR = "REGISTERS_ERROR";
export const REGISTERS_PDF_ERROR = "REGISTERS_PDF_ERROR";

//Daily
export const DAILIES_LOADED = "DAILIES_LOADED";
export const DAILY_REGISTERED = "DAILY_REGISTERED";
export const DAILY_DELETED = "DAILY_DELETED";
export const DAILIES_CLEARED = "DAILIES_CLEARED";
export const DAILY_ERROR = "DAILY_ERROR";
export const DAILIES_ERROR = "DAILIES_ERROR";
export const DAILIES_PDF_ERROR = "DAILIES_PDF_ERROR";

//Grades
export const GRADES_LOADED = "GRADES_LOADED";
export const STUDENTGRADES_LOADED = "STUDENTGRADES_LOADED";
export const GRADETYPES_LOADED = "GRADETYPES_LOADED";
export const GRADES_UPDATED = "GRADES_UPDATED";
export const NEWGRADE_REGISTERED = "NEWGRADE_REGISTERED";
export const GRADES_DELETED = "GRADES_DELETED";
export const GRADETYPES_UPDATED = "GRADETYPES_UPDATED";
export const GRADETYPE_DELETED = "GRADETYPE_DELETED";
export const GRADES_CLEARED = "GRADES_CLEARED";
export const GRADETYPES_CLEARED = "GRADETYPES_CLEARED";
export const GRADES_ERROR = "GRADES_ERROR";
export const GRADETYPE_ERROR = "GRADETYPE_ERROR";

//Expences
export const EXPENCES_LOADED = "EXPENCES_LOADED";
export const EXPENCETYPES_LOADED = "EXPENCETYPES_LOADED";
export const EXPENCE_REGISTERED = "EXPENCE_REGISTERED";
export const EXPENCE_DELETED = "EXPENCE_DELETED";
export const EXPENCETYPES_UPDATED = "EXPENCETYPES_UPDATED";
export const EXPENCETYPE_DELETED = "EXPENCETYPE_DELETED";
export const EXPENCE_CLEARED = "EXPENCE_CLEARED";
export const EXPENCES_CLEARED = "EXPENCES_CLEARED";
export const EXPENCETYPES_CLEARED = "EXPENCETYPES_CLEARED";
export const EXPENCE_ERROR = "EXPENCE_ERROR";
export const EXPENCES_ERROR = "EXPENCES_ERROR";
export const EXPENCETYPE_ERROR = "EXPENCETYPE_ERROR";

//Invoices
export const INVOICE_LOADED = "INVOICE_LOADED";
export const INVOICES_LOADED = "INVOICES_LOADED";
export const INVOICENUMBER_LOADED = "INVOICENUMBER_LOADED";
export const INVOICE_REGISTERED = "INVOICE_REGISTERED";
export const INVOICEDETAIL_ADDED = "INVOICEDETAIL_ADDED";
export const INVOICEDETAIL_REMOVED = "INVOICEDETAIL_REMOVED";
export const PAY_CASH = "PAY_CASH";
export const PAY_TRANSFER = "PAY_TRANSFER";
export const DISCOUNT_ADDED = "DISCOUNT_ADDED";
export const INVOICE_DELETED = "INVOICE_DELETED";
export const INVOICE_CLEARED = "INVOICE_CLEARED";
export const INVOICES_CLEARED = "INVOICES_CLEARED";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const INVOICES_ERROR = "INVOICES_ERROR";

//Enrollments
export const ENROLLMENT_LOADED = "ENROLLMENT_LOADED";
export const ENROLLMENTS_LOADED = "ENROLLMENTS_LOADED";
export const YEARENROLLMENTS_LOADED = "YEARENROLLMENTS_LOADED";
export const ESTIMATEDPROFIT_LOADED = "ESTIMATEDPROFIT_LOADED";
export const ENROLLMENT_REGISTERED = "ENROLLMENT_REGISTERED";
export const ENROLLMENT_UPDATED = "ENROLLMENT_UPDATED";
export const ENROLLMENT_DELETED = "ENROLLMENT_DELETED";
export const ENROLLMENT_CLEARED = "ENROLLMENT_CLEARED";
export const ENROLLMENTS_CLEARED = "ENROLLMENTS_CLEARED";
export const ENROLLMENT_ERROR = "ENROLLMENT_ERROR";
export const ENROLLMENTS_ERROR = "ENROLLMENTS_ERROR";

//Assistance
export const ATTENDANCES_LOADED = "ATTENDANCES_LOADED";
export const ATTENDANCES_UPDATED = "ATTENDANCES_UPDATED";
export const NEWDATE_REGISTERED = "NEWDATE_REGISTERED";
export const DATE_ERROR = "DATE_ERROR";
export const DATE_DELETED = "DATE_DELETED";
export const ATTENDANCES_CLEARED = "ATTENDANCES_CLEARED";
export const ATTENDANCES_ERROR = "ATTENDANCES_ERROR";

//Installments
export const INSTALLMENT_LOADED = "INSTALLMENT_LOADED";
export const INSTALLMENTS_LOADED = "INSTALLMENTS_LOADED";
export const TOTALDEBT_LOADED = "TOTALDEBT_LOADED";
export const INSTALLMENT_REGISTERED = "INSTALLMENT_REGISTERED";
export const INSTALLMENT_ADDED = "INSTALLMENT_ADDED";
export const INSTALLMENT_UPDATED = "INSTALLMENT_UPDATED";
export const INSTALLMENT_DELETED = "INSTALLMENT_DELETED";
export const EXPIREDINSTALLMENTS_UPDATED = "EXPIREDINSTALLMENTS_UPDATED";
export const INSTALLMENT_CLEARED = "INSTALLMENT_CLEARED";
export const INSTALLMENTS_CLEARED = "INSTALLMENTS_CLEARED";
export const INSTALLMENTS_ERROR = "INSTALLMENTS_ERROR";
export const INSTALLMENT_ERROR = "INSTALLMENT_ERROR";

//Towns
export const TOWNS_LOADED = "TOWNS_LOADED";
export const TOWNS_UPDATED = "TOWNS_UPDATED";
export const TOWN_DELETED = "TOWN_DELETED";
export const TOWNS_CLEARED = "TOWNS_CLEARED";
export const TOWNS_ERROR = "TOWNS_ERROR";

//Neighbourhoods
export const NEIGHBOURHOODS_LOADED = "NEIGHBOURHOODS_LOADED";
export const NEIGHBOURHOODS_UPDATED = "NEIGHBOURHOODS_UPDATED";
export const NEIGHBOURHOOD_DELETED = "NEIGHBOURHOOD_DELETED";
export const NEIGHBOURHOODS_CLEARED = "NEIGHBOURHOODS_CLEARED";
export const NEIGHBOURHOODS_ERROR = "NEIGHBOURHOODS_ERROR";
export const NEIGHBOURHOOD_ERROR = "NEIGHBOURHOOD_ERROR";

//Observations
export const OBSERVATIONS_LOADED = "OBSERVATIONS_LOADED";
export const OBSERVATIONS_UPDATED = "OBSERVATIONS_UPDATED";
export const OBSERVATIONS_CLEARED = "OBSERVATIONS_CLEARED";
export const OBSERVATIONS_ERROR = "OBSERVATIONS_ERROR";

//Categories
export const CATEGORIES_LOADED = "CATEGORIES_LOADED";
export const CATEGORIES_UPDATED = "CATEGORIES_UPDATED";
export const CATEGORIES_CLEARED = "CATEGORIES_CLEARED";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
